.select-async {
  .css-1s2u09g-control {
    border-radius: 0.5rem !important;
  }
  .css-1pahdxg-control {
    border-radius: 0.5rem !important;
  }
  // disabled
  .css-1insrsq-control {
    background-color: white;
    border-radius: 0.5rem !important;
  }
  // popup menu
  .css-26l3qy-menu {
    z-index: 3;
  }
  .css-lr9fot-singleValue {
    color: #00000061;
  }
}

label.MuiInputLabel-outlined {
  z-index: 0 !important;
}

.product-attributes {
  z-index: 0;
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    margin-left: -3px;
    font-size: 110%;
  }
}

.asset-autocomplete .MuiAutocomplete-endAdornment {
  .MuiAutocomplete-clearIndicator {
    padding: 2px !important;
  }
  .MuiAutocomplete-popupIndicator {
    padding: 2px !important;
  }
}

.select-paginate-label-shrink {
  transform: translate(14px, -6px) scale(0.75);
  transform-origin: top left;
  padding: 0 5px !important;
  margin-left: -4px;
  background-color: white;
}

.async-paginate-container {
  flex-grow: 1;
  position: relative;
}

.async-paginate-container:focus-within {
  .MuiFormLabel-root {
    @extend .select-paginate-label-shrink;
  }
  .select-paginate-label-shrink {
    color: #5383ff !important;
  }
}
.fake-input {
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
}
