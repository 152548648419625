.app-header {
  background-color: #508cb4;
}

.change-module-selector {
  .MuiSelect-select {
    color: #fefefe;
  }
  .MuiSelect-selectMenu {
    padding: 10px;
  }
  @media (min-width: 650px) {
    min-width: 300px;
  }
}

.change-organization-selector {
  min-width: 400px;
  color: white !important;
}
