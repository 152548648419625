.card-header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding: 0 1.2rem;
  border-bottom: 1px solid rgba(122, 123, 151, 0.3);
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  p {
    margin: 0.7rem 0;
  }
}
.card-header-primary {
  color: #fff;
  background: linear-gradient(90deg, #477bc8, #3abff1);
}
.card-overflow {
  overflow: visible !important;
  .MuiTableContainer-root {
    //overflow-x: visible !important;
  }
}
