.menu-base {
  width: fit-content !important;
}
.menu-item {
  font-size: 12px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover:not(.disabled) {
    background: rgba(0, 0, 0, 0.04);
  }
  &.disabled {
    cursor: default;
  }
}

.menu-item-m {
  min-width: 200px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
}
.menu-item-l {
  min-width: 300px;
}
