.badge-parent {
  position: relative;
}
.c-badge {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  border-radius: 100%;
  height: 18px;
  width: 18px;
}
