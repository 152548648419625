.app-page-title-icon {
  border-radius: 10px;
  padding: 10px;
  background-color: #f4f5fd;
  box-shadow: 0 3px 5px #aaaaaa;
}
.app-page-title-button {
  position: absolute;
  right: 1rem;
  top: 0;
  bottom: 0;
  display: flex;
  @media (max-width: 600px) {
    display: contents;
  }
}
@media print {
  @page {
    margin: 0 !important;
  }

  body {
    margin: 0 !important;
  }
}
