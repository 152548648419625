.button-base {
  background: none;
  border: none;
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.4px;
  font-weight: 500;
  transition: background-color 250ms;
  //   border-radius: $container-radius;
  color: var(--primary-dark);
  svg {
    margin-right: 8px;
  }
  &.outlined {
    border: 2px solid var(--primary-o25);
  }
  &.primary {
    border-color: var(--primary-dark);
    background: var(--primary-dark);
    color: $secondary;
    svg {
      color: $secondary;
    }
  }
  &.active {
    background: var(--primary-o25);
  }
  &:hover:not(:disabled):not(.primary) {
    background-color: var(--primary-o25);
  }
  &:active:not(:disabled) {
    transform: translateY(2px);
  }
  &:disabled {
    opacity: 0.4;
  }
  &.solid {
    background: var(--primary);
    color: $secondary;
    svg {
      color: $secondary;
    }
    &:disabled {
      background: var(--primary-o25);
      color: $secondary;
      svg {
        color: $secondary;
      }
    }
  }
  svg {
    color: var(--primary-dark);
    font-size: 15px;
  }
}

.danger-btn {
  &.primary {
    background: $danger !important;
  }
  &:not(.primary) {
    color: $danger !important;
  }
  border-color: $danger !important;
  svg {
    color: $danger !important;
  }
  &:hover:not(:disabled) {
    background: rgba($danger, 0.25) !important;
  }
}

.icon-button {
  padding: 0;
  svg {
    height: 18px;
    width: 18px;
    padding: 8px;
    margin-right: 0 !important;
  }
}

.no-text-button {
  svg {
    margin: 0 0 0 3px !important;
  }
}

// Sizes
.button-xs {
  height: 23px;
  font-weight: normal;
  padding: 4px 8px;
  border-width: 1px !important;
  svg {
    font-size: 12px;
  }
  &.success {
    background: $success;
    color: $secondary;
    svg {
      color: $secondary;
    }
    &:hover,
    &:disabled {
      background: rgba($success, 0.5);
    }
  }
  &.danger {
    color: $danger;
    border-color: $danger;
    svg {
      color: $danger;
    }

    &:hover:not(:disabled) {
      background: rgba($danger, 0.15);
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
.button-s {
  padding: 4px 10px;
  svg {
    font-size: 15px !important;
  }
}

.button-m {
  height: 36px;
  padding: 6px 16px 6px 12px;
}

.button-simple {
  color: var(--primary-dark);
  text-decoration: underline;
}
.button-outlined {
  color: var(--primary-dark);
  border: 2px solid var(--primary-o25);
  //   border-radius: $container-radius;
  svg {
    margin-left: 10px;
    font-size: 14px;
  }
}

.button-icon {
  width: 20px;
  height: 20px;
  &.success:hover {
    color: $success;
  }
  &.danger:hover {
    color: $danger;
  }
}
