.small-margin-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1.5em;
  width: calc(100% + 3rem);
}
.subheader-btn-container {
  display: flex;
  justify-content: flex-end;
  a {
    margin-right: 1rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    a {
      margin: 0 0 0.5rem 0;
    }
  }
}
.button-center-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.grid-btn-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
}

.img-preview {
  padding: 5px;
  border: 2px solid #bbb;
  border-radius: 15px;
}
