.linear-component-loader {
  width: 200px;
  position: absolute !important;
  top: calc(50% - 50px);
  left: calc(50% - 100px);

  .loader-logo {
    position: relative;
    width: 100px;
    left: calc(50% - 50px);
    padding-bottom: 15px;
  }

  .linear-progress .MuiLinearProgress-barColorPrimary {
    background-color: #4295d8;
  }
}

.overlay-loading {
  opacity: 0.3;
}

.circular-component-loader {
  position: absolute !important;
  top: calc(50% - 50px);
  left: calc(50% - 100px);

  p {
    color: #3b3e66;
    font-weight: 700;
  }

  .MuiCircularProgress-root {
    color: #3b3e66;
    position: absolute !important;
    left: calc(50% - 20px);
  }
}