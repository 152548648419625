.report {
  font-size: 10pt;
  margin: 20px 50px;
  table td,
  table th {
    font-size: 10pt;
  }
}
.reports-table {
  max-width: 100%;
  thead tr {
    background: #f1f1f1;
  }
  th {
    padding: 5px;
    border: 1px solid silver;
  }
  td {
    padding: 3px;
    border: 1px solid silver;
  }
  .spacing td {
    padding: 1px;
  }
  .single-row td:first-child {
    padding-left: 15px;
  }
  .border-bottom-hidden {
    border-bottom-style: hidden !important;
  }
}

.report-table-container {
  border-radius: 0 !important;
}

.page-break-line {
  margin-top: 50px;
}
.left-div {
  width: 100mm;
  float: left;
}
.right-div {
  width: 100mm;
  float: right;
  text-align: right;
}
.center-div {
  width: 100mm;
  margin: 0 auto;
  text-align: center;
}
