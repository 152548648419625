.link-button {
  &:hover {
    background: #508cb4; //#5383ff;
    color: #fff !important;
    padding: 3px 25px 5px;
    border-radius: 10px;
    margin: 0;
  }
  border: 1px solid rgba(61, 73, 119, 0.5);
  border-radius: 8px;
  padding: 0 20px 2px;
  margin: 0 5px;
  transition: 0.3s;
  svg {
    font-size: 15px;
  }
}
