#login {
  .paper {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form {
    width: 100%;
  }
}


