.component-loader {
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
}

.table-no-border-radius .MuiTableContainer-root {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.custom-table thead th {
  background: #f1f1f1;
  border-bottom: 2px solid #dcdef1;
  border-top: 1px solid #dcdef1;
  padding: 6px 12px;
  font-size: 0.85rem;
  color: #3b3e66;
  font-weight: bold;
  text-transform: uppercase;
}
.custom-table thead th span {
  color: #3b3e66 !important;
}
.custom-table tbody td {
  padding: 7px 16px;
}
.custom-table-row-clickable {
  cursor: pointer !important;
}

.items-table {
  th:not(:last-child),
  td:not(:last-child) {
    border-right: 1px solid #dcdef1;
  }
  .MuiTableContainer-root {
    overflow-x: unset;
  }
}

.edit-row {
  background: #3d497714;
  td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  input {
    background: white;
  }
}

.options-cell {
  min-width: 96px;
}

.row-bold {
  font-weight: bold !important;
}

.row-error {
  background-color: #ffe3e3 !important;
}

.break-word-header {
  word-wrap: break-word;
  white-space: normal;
  min-width: 200px;
  text-align: left !important;

  &.w-100 {
    min-width: 100px !important;
  }
}

.table-options {
  display: flex;
  align-items: center;
  padding: 16px 0 4px 8px;
}
.table-container {
  overflow-x: auto;
  overflow-y: clip;
  tbody td {
    height: 52px;
    padding: 7px 16px;
  }
}
.table-head th {
  color: #000;
  font-weight: bold;
  &:last-child {
    .vertical-separator {
      display: none;
    }
  }
}
.table-responsive {
  table {
    width: 100%;
  }
}
.clickable-column {
  color: var(--primary-dark) !important;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-o10) !important;
  }
}
.disabled-column {
  opacity: 0.6;
  background-color: #eee;
}
.table-pagination {
  min-width: 360px;
  border-bottom: none !important;
}

.vertical-separator {
  height: 14px;
  min-width: 2px;
  background-color: #00000030;
}
.edit-row {
  background: #3d497714;
  td {
    padding: 0 3px !important;
  }
  input {
    background: white;
  }
}

.table-icon-btn {
  padding: 0 12px !important;
  border-radius: 10px !important;
}
.no-border {
  border: none !important;
}
.expired-date {
  text-decoration: line-through;
  color: red !important;
  font-weight: bold !important;
}
.word-break {
  word-break: break-word;
}

.table-row-color-sef {
  background-color: #f1f1f1;
}
.table-row-color-warning {
  background-color: #fdc959;
}

.border-bottom-none {
  border-bottom: none !important;
}

.template-switch-icon {
  color: var(--primary-dark);
  font-size: 14px;
}

// New Footer

.table-footer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 7px 16px;
  box-sizing: border-box;
}
.pagination {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.8);
  select {
    color: rgba(0, 0, 0, 0.8);
    background: none;
    border: none;
    text-align: right;
    cursor: pointer;
    padding: 0 8px;
    user-select: none;
    margin-left: 8px;
    margin-right: 16px;
  }
  .offset-input {
    width: 30px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    background: none;
    text-align: center;
    padding: 5px 0;
  }
}

.si-table-info {
  width: fit-content;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
}
