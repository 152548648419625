@media print {
  @page {
    margin: 5mm;
  }
  body {
    overflow: visible !important;
  }
  .report {
    //margin: 0 !important;
  }

  .no-print,
  .page-break-line {
    display: none;
  }

  .page-break {
    page-break-after: always !important;
  }
}

.grouped-row, .total-row {
  td {
    font-weight: bold;
  }
}

// @media print and (orientation: landscape) {
//   @page {
//     width: 842pt;
//   }
// }
// @media print and (orientation: portrait) {
//   @page {
//     width: 595pt;
//   }
// }
