// Core
body {
  .app-header-logo {
    height: $header-height;
    width: $sidebar-width;
    @include media-breakpoint-down(md) {
      display: none;
    }

    display: flex;
    align-items: center;
    padding-left: ($spacer);
    padding-right: ($spacer);
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .app-header-logo-img {
    width: 51px;
    height: 36px;
  }

  .header-logo-wrapper {
    display: flex;
    align-items: center;
  }

  .header-logo-wrapper-btn {
    padding: ($spacer / 3);
    color: $white;
    background: rgba(255, 255, 255, 0.09);

    &:hover {
      background: rgba(255, 255, 255, 0.14);
    }
  }

  .header-logo-wrapper-link {
    text-decoration: none;
  }

  .header-logo-text {
    color: #fefefe;
    padding-left: $spacer / 2;
    font-size: $font-size-base * 1.1;
    font-weight: bold;
    width: auto;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
