.select-wrapper {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #a7b8c4;
}
.select-box {
  border: 0;
  width: 100%;
  height: 100%;
  outline: none;
}
.item-height {
  min-height: 62px;
}
