.module-select {
  flex-grow: 1;
}

.badge-dot {
  .MuiBadge-badge {
    background-color: var(--success);
    color: var(--success);
    box-shadow: 0 0 0 2px #fff;
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      animation: ripple 1.2s infinite ease-in-out;
      border: 1px solid currentColor;
      content: '';
    }
  }
}
@keyframes ripple {
  from {
    transform: 'scale(.8)';
    opacity: 1;
  }
  to {
    transform: scale(2.4);
    opacity: 0;
  }
}
.avatar-icon-initials {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #175c9d;
  color: #fff;
  border-radius: 30px;
}
.header-user-details {
  @media (max-width: 1000px) {
    display: none;
  }
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  &.orange {
    background-color: orange;
  }
}

.dot.blink {
  animation: 1s blinker linear infinite;
  -webkit-animation: 1s blinker linear infinite;
  -moz-animation: 1s blinker linear infinite;

  color: red;
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0.3;
  }
  50% {
    opacity: 0;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

a {
  &.link {
    cursor: pointer;
  }
  &.link:hover {
    text-decoration: underline;
  }
}
