.tree-view {
  flexGrow: 1
}

.add-child-icon {
  padding-left: 8px;
  width: 40px;
}



