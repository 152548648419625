.asset-preview-container {
  box-shadow: 0 -5px 0 #508cb4;
}
.asset-short-info {
  font-size: 0.9em;
  span {
    font-size: 0.85em;
  }
}
.simple-select-label {
  font-size: 16px !important;
  background-color: #ffffff;
}
.underlined-field {
  display: inline-flex;
  width: 180px;
  border-bottom: 1px solid black !important;
  padding: 0 !important;
}

.table-loader {
  position: absolute;
  left: calc(150mm - 40px);
  top: calc(50% - 40px);
}
