.full-width {
  width: 100%;
}
.fullscreen {
  height: 100vh;
  width: 100vw;
}
.text-bold {
  font-weight: bold !important;
}
.text-multiline-left {
  white-space: pre-line;
}
.text-multiline-center {
  white-space: pre-wrap;
}
.auto-width {
  width: auto;
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.border-top-table {
  border-top: 1px solid #c7c7c7;
}
.small-text-field div {
  height: 38px;
}
.small-text-field label {
  margin-top: -7px;
}
.small-text-field label.MuiInputLabel-shrink {
  margin-top: 0;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-grow {
  flex-grow: 1 !important;
}
.justify-left {
  justify-content: left !important;
}
.justify-right {
  justify-content: right !important;
}
.justify-between {
  justify-content: space-between !important;
}
.align-center {
  align-items: center;
}
.float-right {
  float: right;
}
.success-color {
  color: rgb(22, 150, 43);
}
.danger-color {
  color: rgb(192, 42, 42);
}
.h-250 {
  height: 250px;
}
.h-950 {
  height: 950px;
}
.no-border-radius {
  border-radius: 0 !important;
}
